import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

// redux
import { selectPayment } from 'redux/Payment/selectors';

// constants
import { DAYS_PER_WEEK } from 'constants/payments';

// helpers
import { getCardsConfigFoxtrot16, getProductPaymentDataWithSubsHelper } from 'helpers/utilsUpdated';

// types
import { CurrentProduct } from 'types/payments/payments';
import { IPaymentFlow } from 'hooks/types';
import { Colors } from 'wikr-core-components';

const HARDCODED_TRIAL_DURATION = { count: 7, context: 'day' };

export const useCardsAndDisclaimerConfig = ({
    currentProductForPayment,
    timerTime,
    products,
    pageInfo,
    defaultTrialPrice,
    withFullPrice,
}: {
    currentProductForPayment: CurrentProduct;
    timerTime: { time_to: Dayjs; seconds: number };
    products: CurrentProduct[];
    pageInfo: IPaymentFlow;
    defaultTrialPrice: number;
    withFullPrice?: boolean;
}) => {
    const { currency } = useSelector(selectPayment);

    const paymentTypeData = getProductPaymentDataWithSubsHelper(currentProductForPayment);

    const { t } = useTranslation();

    const getDisclaimerWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOn', {
                  fullPrice: pageInfo?.config?.trialFullPrice || defaultTrialPrice,
                  currency: currency.sign,
                  count1: HARDCODED_TRIAL_DURATION.count,
                  count2: paymentTypeData.paymentLabel?.count,
                  context1: HARDCODED_TRIAL_DURATION.context,
                  context2: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentIntroductory.basicDisclaimer.cancelFE', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
              });

    const getDisclaimerWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOff', {
                  fullPrice: pageInfo?.config?.trialFullPrice || defaultTrialPrice,
                  currency: currency.sign,
                  count1: HARDCODED_TRIAL_DURATION.count,
                  count2: paymentTypeData.paymentLabel?.count,
                  context1: HARDCODED_TRIAL_DURATION.context,
                  context2: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentIntroductory.basicDisclaimerForRegularProduct.cancelFE', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
              });

    const getDisclaimerCheckoutWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.checkoutFoxtrot16.disclaimerTrial.timerOn', {
                  fullPrice: pageInfo?.config?.trialFullPrice || defaultTrialPrice,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: currency.sign,
                  count1: paymentTypeData.paymentLabel?.count,
                  count2: HARDCODED_TRIAL_DURATION.count,
                  context1: paymentTypeData.paymentLabel?.context,
                  context2: HARDCODED_TRIAL_DURATION.context,
              })
            : t('paymentFlow.checkoutFoxtrot16.disclaimer.timerOn', {
                  fullPrice: paymentTypeData.fullPrice,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: currency.sign,
                  context: paymentTypeData.paymentLabel?.context,
                  count: +paymentTypeData.paymentLabel?.count,
              });

    const getDisclaimerCheckoutWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.checkoutFoxtrot16.disclaimerTrial.timerOff', {
                  fullPrice: pageInfo?.config?.trialFullPrice || defaultTrialPrice,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: currency.sign,
                  count1: paymentTypeData.paymentLabel?.count,
                  count2: HARDCODED_TRIAL_DURATION.count,
                  context1: paymentTypeData.paymentLabel?.context,
                  context2: HARDCODED_TRIAL_DURATION.context,
              })
            : t('paymentFlow.checkoutFoxtrot16.disclaimer.timerOff', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  context: paymentTypeData.paymentLabel?.context,
                  count: +paymentTypeData.paymentLabel?.count,
              });

    const [disclaimerText, setDisclaimerText] = useState('');
    const [disclaimerCheckoutText, setDisclaimerCheckoutText] = useState('');
    const [isTrialOneWeek, setIsTrialOneWeek] = useState<boolean | undefined>();

    const disclaimerCheckoutColor: Colors = isTrialOneWeek ? 'text-main' : 'text-secondary';

    useEffect(() => {
        setIsTrialOneWeek(currentProductForPayment.trial === DAYS_PER_WEEK);
    }, [currentProductForPayment, timerTime]);

    useEffect(() => {
        if (timerTime?.seconds > 0) {
            setDisclaimerText(getDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek));
            setDisclaimerCheckoutText(getDisclaimerCheckoutWithWeekTrialBeforeTimer(isTrialOneWeek));
        } else {
            setDisclaimerText(getDisclaimerWithWeekTrialAfterTimer(isTrialOneWeek));
            setDisclaimerCheckoutText(getDisclaimerCheckoutWithWeekTrialAfterTimer(isTrialOneWeek));
        }
    }, [timerTime, currentProductForPayment, isTrialOneWeek]);

    const cardsConfigure = getCardsConfigFoxtrot16(products, t, withFullPrice);

    return {
        isTrialOneWeek,
        disclaimerText,
        cardsConfigure,
        disclaimerCheckoutText,
        disclaimerCheckoutColor,
    };
};

import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';

// helpers
import { isEmailConsentBlock, isCountryUS } from 'helpers/utilsUpdated';

// constants
import { DEFAULT_COUNTRY, WITH_UAE_COPYRIGHT_COUNTRIES } from 'constants/countriesList';

const selectUser = (state: RootState) => state.user;

const selectEmail = createSelector(selectUser, (user) => user.email);
const selectOrderId = createSelector(selectUser, (user) => user.orderId);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectEmailStatusMessage = createSelector(selectUser, (user) => user.emailStatus.message);
const selectEmailStatusIsValid = createSelector(selectUser, (user) => user.emailStatus.isValid);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectPaymentType = createSelector(selectUser, (user) => user.payment_type);
const selectGuideId = createSelector(selectUser, (user) => user.guideId);
const selectUserLoader = createSelector(selectUser, (user) => user.loader);
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectIsUSCountry = createSelector(selectUser, (user) => user.country === DEFAULT_COUNTRY);
const selectIsWithUAECopyrightCountry = createSelector(selectUser, (user) =>
    WITH_UAE_COPYRIGHT_COUNTRIES.includes(user.country)
);
const selectDeepLinks = createSelector(selectUser, (user) => user.deepLink);
const selectIsDisplayConsentMailing = createSelector(
    selectUser,
    ({ isConsentMailingActive, isConsentsMailingActiveDisplay, country }) => {
        return (
            isConsentsMailingActiveDisplay && !isConsentMailingActive && isEmailConsentBlock() && !isCountryUS(country)
        );
    }
);

export {
    selectUser,
    selectEmail,
    selectOrderId,
    selectUserId,
    selectEmailStatusMessage,
    selectEmailStatusIsValid,
    selectIsLoading,
    selectToken,
    selectPaymentType,
    selectUserLoader,
    selectCountry,
    selectIsUSCountry,
    selectIsWithUAECopyrightCountry,
    selectGuideId,
    selectDeepLinks,
    selectIsDisplayConsentMailing,
};

import { IPaymentFlow } from './types';
import { CurrentProduct } from '../types/payments/payments';

export interface IUseProducts {
    pageInfo: IPaymentFlow;
}

interface IUseProductsData {
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
}

export function useProductsForNewLayout({ pageInfo }: IUseProducts): IUseProductsData {
    const setIsPreselectedValue = (list: CurrentProduct[], elementIndex: number) => {
        if (list[elementIndex]) list[elementIndex].is_preselected = true;
    };

    const getProductsList = () => {
        let productsListWithDiscount: CurrentProduct[], productsListWithoutDiscount: CurrentProduct[];
        const products = pageInfo.products;

        const elementIndex = products.findIndex((item) => item.is_preselected);

        if (products.length === 3) {
            productsListWithDiscount = [];
            productsListWithoutDiscount = products;
        } else {
            const averageProductValue = products.length / 2;

            productsListWithDiscount = products.filter((product, i) => i < averageProductValue);
            productsListWithoutDiscount = products.filter((product, i) => i >= averageProductValue);
        }

        setIsPreselectedValue(productsListWithDiscount, elementIndex);
        setIsPreselectedValue(productsListWithoutDiscount, elementIndex);

        return {
            productsListWithDiscount,
            productsListWithoutDiscount,
        };
    };

    return getProductsList();
}

import { systemNames } from 'wikr-core-analytics';

import config from 'config';

export interface ICookiePixel {
    name: string;
    id: string;
    config?: { [field: string]: string };
}

interface ICookieAnalyticList {
    [cookieName: string]: ICookiePixel[];
}

export const AMAZON = {
    EVENT_NAME: 'start_session',
};

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';
// for now we don`t do any action for this action, it is pass only if all cookies was allowed on california banner
// we can toggle it in cookies settings - field "Sale of Personal Data"
export const CALIFORNIA_COOKIE = 'SPD_BG';

export const COOKIE_GROUP_LIST = [STRICTLY_NECESSARY_COOKIE, PERFORMANCE_COOKIE, TARGETING_COOKIE];

export const COOKIE_ANALYTIC_LIST: ICookieAnalyticList = {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [
        {
            name: systemNames.ga4,
            id: config.GOOGLE_4_ID,
        },
        {
            name: systemNames.ga,
            id: config.GOOGLE_ID,
            config: {
                googleAdsId: config.GOOGLE_ADS_ID,
                googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
            },
        },
        {
            name: systemNames.pixel,
            id: config.PIXEL_ID,
        },
        {
            name: systemNames.taboola,
            id: config.TABOOLA_ID,
        },
        {
            name: systemNames.outbrain,
            id: config.OUTBRAIN_ID,
        },
    ],
    [SOCIAL_MEDIA_COOKIE]: [],
};

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// redux
import { selectCurrentProduct } from 'redux/Payment/selectors';
import { selectHidePayPal } from 'redux/UiEffects/selectors';

interface IUseSolidPaymentPreInit {
    initPayPal: () => void;
    initPaymentGenerator: () => void;
}

export function useSolidPaymentPreInit({ initPayPal, initPaymentGenerator }: IUseSolidPaymentPreInit) {
    const currentProduct = useSelector(selectCurrentProduct);
    const isHidePayPal = useSelector(selectHidePayPal);

    const currentProductPrev = useRef<object | string | null>(null);

    useEffect(() => {
        // @ts-ignore
        if (document.getElementById('paypal-button') && currentProduct?.id !== currentProductPrev.current?.id) {
            if (!isHidePayPal) {
                initPayPal();
            }

            initPaymentGenerator();

            currentProductPrev.current = currentProduct;
        }
    }, [currentProduct]);
}

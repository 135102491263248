import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import {
    changeExclusiveOfferState,
    setAllProductsFromFirstPayment,
    setCurrentProduct,
    setPaymentDiscount,
} from 'redux/Payment/actions';
import { selectPayment } from 'redux/Payment/selectors';

// helpers
import { getDiscountForPayment, getDiscountForPaymentFixed, isShortPayment } from 'helpers/utils';
import getTimerTime from 'helpers/timer';

// constants
import { EXCLUSIVE_OFFER_PRODUCT } from 'constants/exclusiveOfferProduct';

// selectors
import { IPaymentFlow } from './types';
import { CurrentProduct } from 'types/payments/payments';

export interface IUsePaymentLogic {
    timerKey?: string;
    timerLengthInMin?: number;
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
    pageInfo: IPaymentFlow;
    withCurrentProductSetting?: boolean;
}

export const usePaymentLogic = ({
    timerKey = 'PaymentScreenDefaultTimer',
    timerLengthInMin,
    productsListWithDiscount,
    productsListWithoutDiscount,
    pageInfo,
    withCurrentProductSetting = false,
}: IUsePaymentLogic) => {
    const timerTime = getTimerTime(
        {
            key: timerKey,
        },
        timerLengthInMin
    );

    const discount = useRef(0);

    const productsWithAndWithoutDiscount = [...productsListWithDiscount, ...productsListWithoutDiscount];

    useEffect(() => {
        dispatch(setAllProductsFromFirstPayment(productsWithAndWithoutDiscount));

        return () => {
            dispatch(setPaymentDiscount(discount.current));
        };
    }, []);

    const dispatch = useDispatch();

    const { currentProduct, exclusiveOfferStatus: isExclusiveOfferModalOpen } = useSelector(selectPayment);

    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isTimerEnd, setIsTimerEnd] = useState(timerTime.seconds <= 0);
    const [isOpenCheckoutPopup, setIsOpenCheckoutPopup] = useState(false);
    const [isExclusiveOffer, setIsExclusiveOffer] = useState(false);
    const [currentProductForPayment, setCurrentProductForPayment] = useState<CurrentProduct>(
        (currentProduct as CurrentProduct) || pageInfo.products[0]
    );

    const productsIfThereAreNoDiscountedProducts =
        productsListWithDiscount.length === 0 ? productsListWithoutDiscount : productsListWithDiscount;

    const products = !isTimerEnd ? productsIfThereAreNoDiscountedProducts : productsListWithoutDiscount;

    useEffect(() => {
        if (isExclusiveOfferModalOpen) {
            setPaymentModalOpen(false);
        }
    }, [isExclusiveOfferModalOpen]);

    useEffect(() => {
        if (withCurrentProductSetting) {
            products.forEach((item) => item.is_preselected && setPaymentPrice(item));
        }
    }, [isTimerEnd]);

    const setPaymentPrice = (currentProduct: CurrentProduct) => {
        if (!isPaymentModalOpen) {
            discount.current = getDiscountForPayment(currentProduct, pageInfo);

            dispatch(setCurrentProduct(currentProduct));
            setCurrentProductForPayment(currentProduct);
        }
    };

    const setPaymentPriceNew = (currentProduct: CurrentProduct) => {
        if (!isPaymentModalOpen) {
            discount.current = getDiscountForPaymentFixed(currentProduct, pageInfo);

            dispatch(setCurrentProduct(currentProduct));
        }
    };

    const paymentModalHandler = () => {
        setPaymentModalOpen(false);

        if (isExclusiveOffer) {
            dispatch(changeExclusiveOfferState(true));
        } else {
            const result = isShortPayment();

            if (result) {
                setIsOpenCheckoutPopup(true);
            }
        }
    };

    const openPaymentModal = () => {
        setCurrentProductForPayment(currentProduct as CurrentProduct);
        setPaymentModalOpen(true);
    };

    const onTimerEnd = () => {
        setIsTimerEnd(true);
        discount.current = 0;
    };

    const exclusiveOfferClick = () => {
        setIsExclusiveOffer(true);

        dispatch(setCurrentProduct(EXCLUSIVE_OFFER_PRODUCT));
        setCurrentProductForPayment(EXCLUSIVE_OFFER_PRODUCT);

        setPaymentModalOpen(true);
    };

    return {
        isExclusiveOfferModalOpen,
        isPaymentModalOpen,
        isTimerEnd,
        isOpenCheckoutPopup,
        exclusiveOfferClick,
        paymentModalHandler,
        openPaymentModal,
        setPaymentPrice,
        setPaymentPriceNew,
        onTimerEnd,
        exclusiveOfferProduct: EXCLUSIVE_OFFER_PRODUCT,
        currentProductForPayment,
        timerTime,
        products,
        productsWithAndWithoutDiscount,
        discount,
        setCurrentProductForPayment,
    };
};

import { useSelector } from 'react-redux';

// redux
import { selectPayment } from 'redux/Payment/selectors';

// helpers
import { getProductPaymentData } from 'helpers/utilsUpdated';

// types
import { CurrentProduct } from 'types/payments/payments';

// hooks
import { useAnalytics } from 'hooks/useAnalytics';

interface IUsePaymentIntroductoryContainerArgs {
    toNextPage?: () => void;
    setPaymentPrice: (currentProduct: CurrentProduct) => void;
    url: string;
    customEvent: string;
}

interface IPaymentTypeData {
    paymentType: string;
    currentPaymentType: string;
    paymentLabel: string;
    fullPrice: number;
    trialPrice: number;
    trialLabel: string;
}

interface IUsePaymentIntroductoryContainerReturn {
    currencySign: string;
    paymentTypeData: IPaymentTypeData | null;
    handleCurrentProduct: (data: { withAnalytic?: boolean } & CurrentProduct) => void;
    handlePlanClick: (eventValue: string) => void;
    handleAnalyticData: (eventValue: string, customEvent?: string) => void;
}

export const usePaymentIntroductoryContainer = ({
    toNextPage,
    setPaymentPrice,
    url,
    customEvent,
}: IUsePaymentIntroductoryContainerArgs): IUsePaymentIntroductoryContainerReturn => {
    const { customData, sendAnalyticsWithEventLabel } = useAnalytics(true);

    const { currentProduct, currency } = useSelector(selectPayment);

    const paymentTypeData =
        (currentProduct as CurrentProduct) && getProductPaymentData(currentProduct as CurrentProduct);

    const handleCurrentProduct = (data: { withAnalytic?: boolean } & CurrentProduct) => {
        const { withAnalytic, ...product } = data;

        const productData = getProductPaymentData(product);

        setPaymentPrice(product);

        withAnalytic && handleAnalyticData(productData?.currentPaymentType, customEvent);
    };

    const handleAnalyticData = (eventValue: string, customEvent?: string) => {
        if (customEvent) {
            customData(customEvent, { event_label: eventValue });
        } else {
            sendAnalyticsWithEventLabel({
                url: url,
                eventValue,
            });
        }
    };

    const handlePlanClick = (eventValue: string) => {
        handleAnalyticData(eventValue);

        toNextPage?.();
    };

    return {
        currencySign: currency.sign,
        paymentTypeData,
        handleCurrentProduct,
        handlePlanClick,
        handleAnalyticData,
    };
};

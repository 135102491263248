import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './ModalClose.module.css';

// types
import { IModalCloseProps } from './types';

const cx = classNames.bind(styles);

function ModalClose({
    notificationModalIcon,
    isLeftClose,
    onClick = () => {},
    isFixed,
    isSmallClose,
    withTimer,
    isActiveTimer,
}: IModalCloseProps) {
    const modalStyles = cx('closePopup', {
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
        closePopup__checkoutWithTimerOn: withTimer && isActiveTimer,
        closePopup__checkoutWithTimerOff: withTimer && !isActiveTimer,
    });

    return <span className={modalStyles} onClick={onClick} />;
}

export default ModalClose;

import { useEffect } from 'react';

// helpers
import { scrollToTop } from 'helpers/utilsUpdated';

export function useScrollToTop() {
    useEffect(() => {
        scrollToTop();
    }, []);
}

import { takeLatest, put } from 'redux-saga/effects';
import Solid from 'solid-payment';
import { InitPayPal } from 'solid-payment/src/interfaces/payment';

// services
import sentry from 'services/Sentry/SentryInstance';

// constants
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';

// redux
import { setInitPaymentData, setOrderId } from 'redux/Payment/actions';

// actionTypes
import * as actionTypes from 'redux/Payment/actionTypes';

// interfaces
import { ActionType } from 'types/commonInterfaces';
import { PaymentData } from 'types/payments/paymentApiInterfaces';

export function* init({ payload }: ActionType<InitPayPal>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        const response: PaymentData = yield Solid.init('payPal', payload);

        yield put(setInitPaymentData(response));
        yield put(setOrderId(response?.order?.order_id));
    } catch (e) {
        console.warn('Error init');

        sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...payload,
        });
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];

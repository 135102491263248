import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Routers } from './router';

// hooks
import { useAppInit } from 'hooks';

// components
import Header from 'components/Header';
import NotificationModal from 'components/NotificationModal/NotificationModal';
import ReleaseConfig from 'components/ReleaseConfig/ReleaseConfig';
import InitTestania from 'pages/GeneralPages/InitTestania';

// HOC
import withHeaderConfig from 'HOC/withHeaderConfig';

// services
import { OneTrustProvider } from 'services/OneTrust';
import { ThemeProvider } from 'services/Theme';

// styles
import './styles.css';

const HeaderWithConfig = withHeaderConfig(Header);

const App = () => {
    useAppInit();

    return (
        <OneTrustProvider>
            <ThemeProvider>
                {/* @ts-ignore*/}
                <BrowserRouter>
                    {/* @ts-ignore*/}
                    <HeaderWithConfig />
                    <ReleaseConfig />
                    <React.Suspense fallback={null}>
                        <InitTestania />
                        <Routers />
                    </React.Suspense>
                </BrowserRouter>
                <NotificationModal />
            </ThemeProvider>
        </OneTrustProvider>
    );
};

export default App;

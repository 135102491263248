import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './User/reducer';
import OnboardingReducer from './Onboadring/reducer';
import PaymentReducer from './Payment/reducer';
import UiEffects from './UiEffects/reducer';
import TestaniaReducer from './Testania/reducer';

// types
import { PartialPreloadedState } from './types';

const appReducer = combineReducers({
    user: UserReducer,
    onboarding: OnboardingReducer,
    uiEffects: UiEffects,
    payment: PaymentReducer,
    testania: TestaniaReducer,
});

const rootReducer = (state: any, action: any) => {
    // when a reset_state action is dispatched it will reset redux state
    if (action.type === 'RESET_STATE') {
        localStorage.removeItem('isResetStore');

        state = undefined;
    }

    return appReducer(state, action);
};

export const setupTestingStore = (preloadedState?: PartialPreloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const SET_GENDER = 'SET_GENDER';
export const SET_GOAL = 'SET_GOAL';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_ZONE = 'SET_TARGET_ZONE';
export const SET_ACTIVITY_LVL = 'SET_ACTIVITY_LVL';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_FITNESS_LEVEL = 'SET_FITNESS_LEVEL';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_MOTIVATION = 'SET_MOTIVATION';
export const SET_DINNER_TIME = 'SET_DINNER_TIME';
export const SET_LUNCH_TIME = 'SET_LUNCH_TIME';
export const SET_LIFE_STYLE = 'SET_LIFE_STYLE';
export const SET_BREAKFAST_TIME = 'SET_BREAKFAST_TIME';
export const SET_FASTING_FAMILIARITY = 'SET_FASTING_FAMILIARITY';
export const SET_FASTING_LEVEL = 'SET_FASTING_LEVEL';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_OCCASION_DATE = 'SET_OCCASION_DATE';
export const JOB_ACTIVE = 'JOB_ACTIVE';
export const SET_AGE_GROUP = 'SET_AGE_GROUP';
export const SET_FEELINGS = 'SET_FEELINGS';
export const SET_DIET = 'SET_DIET';
export const SET_EXERCISE = 'SET_EXERCISE';

import Base from './Base';

// types
import {
    DeepLinkData,
    HashedEmailResp,
    SignInData,
    SignUpData,
    UserInfoResponse,
    UserUpdateData,
} from 'types/user/userApiInterface';

class UserApi extends Base {
    async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/');
    }

    async update<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.patch<Data, Response>('user/', payload);
    }

    async signIn<Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.public.post<Data, Response>('sign-in/', payload);
    }

    async signUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.public.post<Data, Response>('sign-up/', payload);
    }

    async deepLinks<Response = DeepLinkData>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/auth-deeplink');
    }

    async updateConsent<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('user/', payload);
    }

    async hashedEmail() {
        return await this.apiClient.private.get<HashedEmailResp>('front-hash');
    }
}

export default UserApi;

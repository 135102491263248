import { useMeasureUnit } from 'hooks';
import { useSelector } from 'react-redux';

// redux
import { selectOnboarding } from 'redux/Onboadring/selectors';

// constants
import { WEIGHT_UNIT } from 'constants/measureUnits';

// helpers
import { getTargetDate } from 'helpers/utilsUpdated';
import Convert from 'helpers/Convert/ConvertNew';

const converter = new Convert();

export function useDatePrognoseData(extraDate: number) {
    const { measureSystem } = useMeasureUnit();
    const { target_weight, current_weight, tall } = useSelector(selectOnboarding);
    const targetWeight = Number(target_weight);
    const currentWeight = Number(current_weight);

    const unit = WEIGHT_UNIT[measureSystem];
    const bmi: number = converter.getBMI(currentWeight, Number(tall));
    const weightDiff: number = currentWeight - targetWeight;
    const prognoseDate = getTargetDate({ weightDiff, bmi, extraDate });

    const targetDate = getTargetDate({ weightDiff, bmi });

    return {
        unit,
        bmi,
        weightDiff: Math.round(weightDiff),
        targetDate,
        prognoseDate,
        targetWeight: Math.round(targetWeight),
        currentWeight: Math.round(currentWeight),
    };
}

import sentry from './SentryInstance';
import { ERROR_LEVELS } from 'sentry-utils';
import { SENTRY_SIGN_UP_LOGGER } from 'constants/sentry';
import { Main } from 'wikr-core-analytics';

class SentrySignUpLogger {
    private signUpEmailDuplicate: string;
    private timestamp: number;
    private awsId: string;

    constructor() {
        this.signUpEmailDuplicate = '';
        this.timestamp = 0;
    }

    private static async getAnalyticData() {
        const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

        return isInitAmazonAnalytic && (await Main?.getDefaultAmazonAnalyticPurchaseData());
    }

    async logDuplicate({ actionName, email }: { actionName: string; email: string }) {
        const { aws_id: awsId } = await SentrySignUpLogger.getAnalyticData();
        const timestamp = new Date().getTime();

        if (!this.signUpEmailDuplicate.length && email) {
            this.signUpEmailDuplicate = email;
            this.timestamp = timestamp;
            this.awsId = awsId;
        }

        const timestampDiff = timestamp - this.timestamp;
        const isTimestamp = timestampDiff > 0 && timestampDiff < 1000;
        if (this.signUpEmailDuplicate === email && this.awsId && this.awsId === awsId && isTimestamp) {
            sentry.logError(
                { name: `Log duplicate - ${actionName}`, message: `Message for duplicate ${actionName}` },
                SENTRY_SIGN_UP_LOGGER,
                ERROR_LEVELS.CRITICAL,
                {
                    ...(actionName && { actionName }),
                    awsId: this.awsId,
                    firstTimestamp: this.timestamp,
                    secondTimestamp: timestamp,
                    email,
                }
            );
        }
    }
}

const SentrySignUpLoggerInstance = new SentrySignUpLogger();

export default SentrySignUpLoggerInstance;

/* eslint-disable max-lines */
export const onboardingScreensList = [
    'ob-walking-goal-main',
    'ob-height-a',
    'ob-weight-current-a',
    'ob-weight-target-a',
    'ob-loose-weight-reason',
    'ob-loose-weight-reason-a',
    'ob-age-a',
    'ob-walking-typical-day',
    'ob-walking-energy',
    'ob-walking-fitness-level',
    'ob-walking-time-walk',
    'ob-walking-stairs',
    'ob-walking-habits',
    'ob-walking-job-load',
    'ob-walking-sleep',
    'ob-walking-motivation',
    'ob-walking-goal-steps',
    'ob-fasting-awareness-a',
    'ob-fasting-awareness',
    'ob-awareness-config',
    'ob-fasting-time-breakfast',
    'ob-time-breakfast',
    'ob-fasting-time-lunch',
    'ob-time-lunch',
    'ob-fasting-time-dinner',
    'ob-time-dinner',
    'ob-fasting-pref-eat-place',
    'ob-pref-eat-place',
    'ob-fasting-weekend',
    'ob-weekend',
    'ob-fasting-workout',
    'ob-workout',
    'ob-fasting-job-load',
    'ob-job-load',
    'ob-fasting-job-active',
    'ob-job-active',
    'ob-fasting-sleep',
    'ob-sleep',
    'ob-fasting-water',
    'ob-water',
    'ob-fasting-motivation',
    'ob-motivation',
    'ob-fasting-happy-weight',
    'ob-happy-weight',
    'ob-attempts',
    'ob-health-conditions',
    'ob-goal-2',
    'ob-emotions-1',
    'ob-emotions-2',
    'ob-emotions-3',
    'ob-emotions-4',
    'ob-pace-goal',
    'ob-goal',
    'ob-regular-activity',
    'ob-last-time',
    'ob-bad-habits',
    'ob-physical-activity',
    'ob-capacity',
    'result-mealplan',
    'ob-bodytype',
    'ob-motivation',
    'ob-motivation-config',
    'ob-behavior',
    'ob-mealplan-vegetables',
    'ob-mealplan-protein',
    'ob-rest',
    'ob-hydration',
    'dem-motive-goal',
    'active-flev-act-body',
    'active-flev-targ-body',
    'active-flev-targ-z',
    'behave-life-beasy',
    'behave-hist-perfectW8',
    'nutr-habbit-feel',
    'active-habit-slip',
    'nutr-habbit-water',
    'behave-psych-lazy',
    'behave-psych-busy',
    'diets',
    'nutr-routine-preferences',
    'ob-active-level',
    'fasting-awareness',
    'walking-time',
    'walking-how-far',
    'walking-goal',
    'walking-feel-stairs',
    'ob-creating-1',
    'ob-creating-3',
    'email-a',
    'email',
    'ob-pushups-squats',
    'ob-fitness-run',
    'ob-fitness-walking',
    'load-bar',
    'ob-step-goal',
    'interests-a',
    'ob-occasion',
    'ob-dynamic-weight-loss-2',
    'ob-dynamic-weight-loss-3',
    'ob-dynamic-weight-loss-4',
    'ob-target-zones',
    'ob-body-type-target',
    'ob-body-type-current',
    'ob-goal-set',
    'ob-goal-set-a',
    'ob-goals',
    'ob-workout-a',
    'ob-stairs',
    'ob-walking',
    'ob-back-issue',
    'ob-back-issue-config',
    'ob-statement-food-emotions',
    'ob-statement-clear-plate',
    'ob-food-in-fridge',
    'ob-food-yellow-list',
    'ob-food-green-list',
    'ob-food-red-list',
    'ob-food-intro',
    'ob-food-red-info',
    'ob-food-green-info',
    'ob-food-yellow-info',
    'ob-pregnant',
    'ob-medications',
    'ob-unfortunately',
    'ob-restrictive-diet',
    'ob-goals-old',
    'ob-cheat-meal',
    'ob-weight-target-b',
    'ob-weight-target-c',
    'ob-target-weight-confirmation',
    'ob-occasion-result',
    'ob-behave-choice-sc',
    'ob-behave-eating-sc',
    'ob-behave-multitasking-sc',
    'ob-behave-routines-sc',
    'ob-behave-oldhabits-sc',
    'ob-behave-impulse-sc',
    'ob-what-is-fasting-a',
    'ob-what-is-fasting-b',
    'ob-tags-a',
    'ob-tags-b',
    'ob-goals-mix',
    'ob-goals-config',
    'ob-goals-mix-a',
    'ob-goals-mix-b',
    'ob-body-type-current-mix',
    'ob-body-type-current-mix-a',
    'ob-body-type-target-mix',
    'ob-body-type-target-mix-a',
    'ob-target-zones-mix',
    'ob-workout-mix',
    'ob-skin-tone',
    'ob-feelings-weight',
    'ob-weight-current-c',
    'ob-weight-target-e',
    'ob-16-8-fasting',
    'ob-fortunately',
    'ob-benefits',
    'ob-fasting-interval-a',
    'ob-myths-a',
    'ob-myths-b',
    'ob-myths-c',
    'ob-body-type-current-morph',
    'email-b',
    'email-c',
    'email-d',
    'email-e',
    'email-f',
    'email-config',
    'email-config-a',
    'ob-weekly-effect-a',
    'ob-weekly-effect',
    'ob-weekly-effect-b',
    'ob-feeling',
    'ob-feeling-a',
    'success-statistics',
    'goal-date-prognose-1',
    'goal-date-prognose-2',
    'ob-tempo',
    'ob-diets',
    'result-weight-only-a',
    'result-weight-only-b',
    'ob-exercises',
    'ob-exercises-feedback',
    'ob-feelings',
    'ob-feelings-a',
    'ob-feelings-a-feedback',
    'ob-trackers-feedback',
    'ob-fitness-tracker',
    'ob-scale',
    'ob-motivation-1',
    'ob-motivation-2',
    'ob-motivation-3',
    'ob-priorities',
    'ob-goal-1',
    'ob-snack-triggers',
    'ob-snack',
    'ob-yoyo-diets',
    'ob-gender',
    'ob-target-zones-mix-a',
    'ob-target-zones-mix-b',
    'ob-target-zones-config',
    'ob-experts',
    'ob-welcome-to-fe',
];

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

// redux
import { selectOnboarding } from 'redux/Onboadring/selectors';

// helpers
import Convert from 'helpers/Convert';
import { getBMIBasedWeightLossIndexPerWeek, getToFixedNumber } from 'helpers/utilsUpdated';
import { getWeightValue, roundTo } from 'helpers/DynamicWeightLoss/helpers';

// constants
import { OCCASION_COEFFICIENT } from 'constants/obDynamicWeightLoss';

// types
import { useOccasionCalculationArgs } from './types';

const convert = new Convert();
const DEFAULT_OCCASION_DATE_DIFF = 90;

export const useOccasionCalculation = ({
    reductionCoefficient = OCCASION_COEFFICIENT.first,
    unit,
}: useOccasionCalculationArgs) => {
    const { tall, occasionDate, current_weight, target_weight } = useSelector(selectOnboarding);

    const currentWeight = getWeightValue({ unit, value: current_weight as string });
    const targetWeight = getWeightValue({ unit, value: target_weight as string });

    const bmi = convert.getBMI(current_weight, tall);
    const today = dayjs().toDate();
    const defaultOccasionDate = dayjs().add(DEFAULT_OCCASION_DATE_DIFF, 'days').toDate();
    const weekToOccasionDate = dayjs(occasionDate || defaultOccasionDate).diff(today, 'w');

    const losePerWeek = getBMIBasedWeightLossIndexPerWeek(bmi) * reductionCoefficient;

    let lostWeight = 0;

    for (let i = 0; i < weekToOccasionDate; i++) {
        if (currentWeight - targetWeight <= lostWeight + losePerWeek) {
            lostWeight = currentWeight - targetWeight;
            break;
        }

        lostWeight += losePerWeek;
    }

    const lostWeightConverted = getWeightValue({ unit, value: lostWeight.toString() });
    const occasionWeight = getToFixedNumber(currentWeight - lostWeightConverted, 0);

    return {
        occasionWeight,
        lostWeight: roundTo(lostWeight, 1),
        occasionDate,
    };
};

import * as actionTypes from './actionTypes';

// helpers
import { getKgWeight } from 'helpers/utils';
import { getNumberSystem } from 'helpers/utilsUpdated';

// interfaces
import { OnboardingStore } from 'types/onboarding/onboardingStore';
import { InferValueTypes } from 'types/commonInterfaces';

import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: OnboardingStore = {
    gender: null,
    goal: null,
    current_body: null,
    target_bodytype: null,
    target_zone: null,
    target_weight: 85,
    activityLevel: null,
    fitness_level: null,
    age: 0,
    tall: null,
    current_weight: null,
    measureSystem: 'IMPERIAL',
    productsList: [],
    onboardingScreensList: {},
    fastingMotivation: null,
    dinnerTime: null,
    lunchTime: null,
    breakfastTime: null,
    lifeStyle: null,
    fastingFamiliarity: null,
    fastingLevel: 'Only the name',
    occasion: null,
    occasionDate: null,
    urlParams: null,
    jobActive: null,
    ageGroup: null,
    feelings: null,
    diet: null,
    exercise: null,
};

const OnboardingReducer = (state = initialState, action: ActionType): OnboardingStore => {
    switch (action.type) {
        case actionTypes.SET_GENDER:
            localStorage.setItem('gender', action.payload as string);

            return { ...state, gender: action.payload };
        case actionTypes.SET_CURRENT_BODY:
            return { ...state, current_body: action.payload };
        case actionTypes.SET_GOAL:
            return { ...state, goal: action.payload };
        case actionTypes.SET_TARGET_BODY:
            return { ...state, target_bodytype: action.payload };
        case actionTypes.SET_TARGET_ZONE:
            return { ...state, target_zone: action.payload };
        case actionTypes.SET_CURRENT_WEIGHT: {
            const currentWeight = getKgWeight(action.payload);
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, current_weight: currentWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_TARGET_WEIGHT: {
            const targetWeight = getKgWeight(action.payload);
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, target_weight: targetWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_ACTIVITY_LVL:
            return { ...state, activityLevel: action.payload };
        case actionTypes.SET_FITNESS_LEVEL:
            return { ...state, fitness_level: action.payload };
        case actionTypes.SET_AGE:
            return { ...state, age: action.payload };
        case actionTypes.SET_TALL: {
            const tall = action.payload.value;
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, tall, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_MEASURE_SYSTEM:
            return { ...state, measureSystem: action.payload };
        case actionTypes.SET_ONBOARDING_SCREENS_LIST:
            return { ...state, onboardingScreensList: action.payload };

        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return { ...state, urlParams: action.payload };
        }
        case actionTypes.SET_MOTIVATION: {
            return { ...state, fastingMotivation: action.payload };
        }
        case actionTypes.SET_DINNER_TIME: {
            return { ...state, dinnerTime: action.payload };
        }
        case actionTypes.SET_LUNCH_TIME: {
            return { ...state, lunchTime: action.payload };
        }
        case actionTypes.SET_BREAKFAST_TIME: {
            return { ...state, breakfastTime: action.payload };
        }
        case actionTypes.SET_LIFE_STYLE: {
            return { ...state, lifeStyle: action.payload };
        }
        case actionTypes.SET_FASTING_FAMILIARITY: {
            return { ...state, fastingFamiliarity: action.payload };
        }
        case actionTypes.SET_FASTING_LEVEL: {
            return { ...state, fastingLevel: action.payload };
        }
        case actionTypes.SET_OCCASION: {
            return { ...state, occasion: action.payload };
        }
        case actionTypes.SET_OCCASION_DATE: {
            return { ...state, occasionDate: action.payload };
        }
        case actionTypes.JOB_ACTIVE: {
            return { ...state, jobActive: action.payload };
        }
        case actionTypes.SET_AGE_GROUP: {
            return { ...state, ageGroup: action.payload };
        }
        case actionTypes.SET_FEELINGS: {
            return { ...state, feelings: action.payload };
        }
        case actionTypes.SET_DIET: {
            return { ...state, diet: action.payload };
        }
        case actionTypes.SET_EXERCISE: {
            return { ...state, exercise: action.payload };
        }
        default:
            return state;
    }
};

export default OnboardingReducer;

import { IPaymentFlow } from './types';

export interface IUseProducts {
    pageInfo: IPaymentFlow;
}

interface IUseProductsData {
    buttonColorsClass: string;
    buttonText: string;
}

const getCheckoutBtnColor = (color: string): string => {
    switch (color) {
        case 'green':
            return 'btnSuccessColor';
        default:
            return '';
    }
};

const getCheckoutBtnText = (text: string): string => {
    switch (text) {
        case '1':
            return 'paymentFlow.checkoutFoxtrot5.mainBtnText.confirm';
        case '2':
            return 'paymentFlow.checkoutFoxtrot5.mainBtnText.payNowLock';
        case '4':
            return 'paymentFlow.checkoutFoxtrot5.mainBtnText.payNowArrows';
        case '3':
        default:
            return 'paymentFlow.checkoutFoxtrot5.mainBtnText.payNow';
    }
};

export function useCheckoutConfiguration({ pageInfo }: IUseProducts): IUseProductsData {
    const config = pageInfo?.config;

    const buttonColorsClass = getCheckoutBtnColor(config?.buttonColor);
    const buttonText = getCheckoutBtnText(config?.buttonText);

    return { buttonColorsClass, buttonText };
}

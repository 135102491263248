import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { selectMeasureSystem } from 'redux/Onboadring/selectors';
import { setMeasure } from 'redux/Onboadring/actions';

// constants
import { MEASURE_SYSTEM } from 'constants/measureUnits';

export interface UseMeasureUnitReturn {
    measureSystem: string;
    setMeasureSystem: (unit: string) => void;
    getMeasureSystem: (unit: string) => string;
}

export function useMeasureUnit(): UseMeasureUnitReturn {
    const measureSystem: string = useSelector(selectMeasureSystem);
    const dispatch = useDispatch();

    const [measureSystemValue, setMeasureSystemValue] = useState(measureSystem);

    const setMeasureSystem = (unit: string) => {
        dispatch(setMeasure(unit));

        setMeasureSystemValue(unit);
    };

    const getMeasureSystem = (unit: string) => MEASURE_SYSTEM[unit];

    return {
        measureSystem: measureSystemValue,
        setMeasureSystem,
        getMeasureSystem,
    };
}

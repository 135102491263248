import config from 'config';

import * as actionTypes from './actionTypes';

// interfaces
import { UiEffectStore } from 'types/uiEffects/uiEffectStore';
import { InferValueTypes } from 'types/commonInterfaces';

// actions
import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UiEffectStore = {
    hide_header: null,
    modalIsOpen: false,
    issueType: 'ERROR',
    issueTitle: '',
    issueDescription: '',
    appLoader: false,
    hideProgressBar: null,
    hidePayPal: !!JSON.parse(config?.HIDE_PAYPAL || 'false'),
    initFrontChat: null,
    hideFrontChat: !!JSON.parse(config?.HIDE_FRONT_CHAT || 'false'),
};

const UiEffects = (state = initialState, action: ActionType): UiEffectStore => {
    switch (action.type) {
        case actionTypes.HIDE_HEADER:
            return { ...state, hide_header: action.payload };

        case actionTypes.SHOW_MODAL: {
            // @ts-ignore
            return { ...state, modalIsOpen: true, ...action.payload };
        }

        case actionTypes.HIDE_MODAL: {
            return { ...state, modalIsOpen: false, issueDescription: '', issueTitle: '' };
        }
        case actionTypes.SET_LOADER_STATE: {
            return { ...state, appLoader: action.payload };
        }

        case actionTypes.HIDE_PROGRESS_BAR: {
            return { ...state, hideProgressBar: action.payload };
        }

        case actionTypes.INIT_FRONT_CHAT:
            return { ...state, initFrontChat: action.payload };

        default:
            return state;
    }
};

export default UiEffects;

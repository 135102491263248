import Solid from 'solid-payment';
import { takeLatest, put } from 'redux-saga/effects';
import { InitPaymentGenerator } from 'solid-payment/src/interfaces/payment';

// services
import sentry from 'services/Sentry/SentryInstance';

// constants
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';

// actionTypes
import * as actionTypes from 'redux/Payment/actionTypes';
import { setMerchantData } from 'redux/Payment/actions';

// interfaces
import { ActionType } from 'types/commonInterfaces';
import { InitConfig } from '@solidgate/client-sdk-loader';

export function* init({ payload }: ActionType<InitPaymentGenerator>) {
    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });
        const response: InitConfig['merchantData'] = yield Solid.init('paymentGenerator', payload);

        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
        yield put(setMerchantData(response));
    } catch (e) {
        console.error('Init payment error', e);

        sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...payload,
        });
    }
}

export const initPaymentGenerator = [takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init)];

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// helpers
import { setAnalyticData, setUserPlatform } from './helpers';
import { setRoutesList } from 'helpers/setRoutesList';

// services
import { initAmazonAnalytic, initHotjar } from 'services/Analytics';

// redux
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectUser } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { selectUrlParams } from 'redux/Onboadring/selectors';

// constants
import { LTV_COEFFICIENT_KEY, DEFAULT_LTV_COEFFICIENT } from 'constants/payments';

export function useAppInit() {
    const dispatch = useDispatch();

    const prevCountry = useRef<string | null>(null);
    const prevAbTestName = useRef<string | null>(null);
    const prevUserId = useRef<string | number | null>(null);

    const { country, user_id: userId } = useSelector(selectUser);
    const abTestName = useSelector(selectTestaniaName);
    const urlParams = useSelector(selectUrlParams);

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
        prevUserId.current = userId;
    };

    useEffect(() => {
        const testaniaStatus = localStorage.getItem('testaniaResponseStatus');

        localStorage.setItem(LTV_COEFFICIENT_KEY, String(DEFAULT_LTV_COEFFICIENT));

        setNewPropsToRef();
        setAnalyticData({ country, abTestName, userId });
        setUserPlatform(dispatch);

        initAmazonAnalytic();

        if (testaniaStatus) {
            dispatch(setAppLoader(false));
        }

        initHotjar();

        setRoutesList();
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName || prevUserId.current !== userId) {
            setAnalyticData({ country, abTestName, userId });
            setNewPropsToRef();
        }
    }, [country, abTestName, urlParams, userId]);
}

export default useAppInit;

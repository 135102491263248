/* eslint-disable no-console, max-lines */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IUseProducts } from './useProducts';
import { Main } from 'wikr-core-analytics';

// redux
import { selectUser } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';
import {
    initPayPal as initPayPalAction,
    initPaymentGenerator as initPaymentGeneratorAction,
    setLoadingStatus,
    changePaymentMethod,
    setIsPaymentScreen,
    setCustomPaymentButton,
    setIsApplePayAvailable,
    setIsGooglePayAvailable,
} from 'redux/Payment/actions';

// type
import { Currency, PaymentMethod } from 'types/payments/payments';
import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';

// services
import sentry from 'services/Sentry/SentryInstance';

// constants
import {
    CREDIT_CARD_LITERAL,
    PAYMENT_TYPES,
    PAYMENT_TYPES_NAME,
    PRESELECTED_PAYMENT_TYPE,
    SOLID_ERROR_CODES,
} from 'constants/payments';
import { ERROR_LEVELS, SENTRY_PAYMENT } from 'sentry-utils';

// hooks
import { useSolidPaymentPreInit, useValidatePayment } from 'hooks';

interface IUsePaymentLib extends IUseProducts {
    toNextPage: ({ isValid }: { isValid: boolean }) => void;
    hasCustomButton?: boolean;
    withPreselect?: boolean;
}

interface IUsePaymentLibData {
    isLoading: boolean;
    paymentType: string;
    handleChangePaymentMethod: (type: PaymentMethod) => void;
    payment: {
        initPayPal: () => void;
        initPaymentGenerator: () => void;
    };
}

const HAS_DIRECTLY_PRESELECT_LIST = ['paypal', 'bank_card'];

export function usePaymentLib({
    pageInfo,
    toNextPage,
    hasCustomButton = true,
    withPreselect = false,
}: IUsePaymentLib): IUsePaymentLibData {
    const { user_id, country } = useSelector(selectUser);
    const { isLoading, currency, currentProduct, validatePaymentData, isApplePayAvailable } = useSelector(
        selectPayment
    );

    const validatePayment = useValidatePayment();
    const dispatch = useDispatch();

    useSolidPaymentPreInit({ initPayPal, initPaymentGenerator });

    const paymentType = pageInfo.products[0].payment_type;

    useEffect(() => {
        if (withPreselect) {
            const config = pageInfo.config;

            if (config.paymentMethod && HAS_DIRECTLY_PRESELECT_LIST.includes(`${config.paymentMethod}`)) {
                dispatch(changePaymentMethod(PRESELECTED_PAYMENT_TYPE[config.paymentMethod]));
            }

            if (!config.paymentMethod || config.paymentMethod === PAYMENT_TYPES_NAME.GOOGLE_PAY) {
                dispatch(changePaymentMethod(CREDIT_CARD_LITERAL));
            }
        }
    }, [pageInfo.config.paymentMethod]);

    useEffect(() => {
        if (withPreselect) {
            const config = pageInfo.config;

            if (isApplePayAvailable && config.paymentMethod === PAYMENT_TYPES_NAME.APPLE_PAY) {
                dispatch(changePaymentMethod(PRESELECTED_PAYMENT_TYPE[config.paymentMethod]));
            }
            if (!isApplePayAvailable && config.paymentMethod === PAYMENT_TYPES_NAME.APPLE_PAY) {
                dispatch(changePaymentMethod(CREDIT_CARD_LITERAL));
            }
        }
    }, [isApplePayAvailable]);

    useEffect(() => {
        // @ts-ignore
        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            toNextPage({
                // @ts-ignore
                isValid: validatePaymentData?.result,
            });
        }
    }, [validatePaymentData]);

    useEffect(() => {
        if (hasCustomButton) {
            dispatch(setCustomPaymentButton(true));

            return () => {
                dispatch(setCustomPaymentButton(false));
            };
        }
    }, []);

    useEffect((): (() => void) => {
        dispatch(setLoadingStatus(true));
        dispatch(setIsPaymentScreen(true));

        return () => dispatch(setIsPaymentScreen(false));
    }, []);

    const getCurrencyName = (currency: Currency): string => currency.name;

    function initPayPal() {
        const meta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            // @ts-ignore
            price: currentProduct?.fullPrice || 1,
            country,
            currency: getCurrencyName(currency),
            successHandler: (data: DataForValidate) => {
                if (data?.error?.code) {
                    console.log('successHandler paypal error', data);

                    dispatch(setLoadingStatus(false));
                    initPayPal();

                    if (SOLID_ERROR_CODES.includes(data?.error?.code)) {
                        sentry.logError(
                            new Error('PayPal error'),
                            SENTRY_PAYMENT,
                            ERROR_LEVELS.CRITICAL,
                            {
                                // @ts-ignore
                                ...currentProduct,
                                ...meta,
                            },
                            [
                                ['SOLID_ERROR_CODE', data?.error?.code],
                                ['SOLID_ERROR_MESSAGE', data?.error?.recommended_message_for_user],
                                ['SOLID_METHOD', data?.order?.method],
                            ]
                        );
                    }
                } else {
                    validatePayment('payPal', meta.payment_method, data);
                }
            },
            errorHandler: (e: Error) => {
                sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
                    // @ts-ignore
                    ...currentProduct,
                    ...meta,
                });
                dispatch(setLoadingStatus(false));
            },
            onClickHandler: () => {
                dispatch(setLoadingStatus(true));

                Main.customData('processor_form__click', { payment: 'paypal', event_label: 'paypal' });
            },
            readyHandler: () => {
                dispatch(setLoadingStatus(false));

                Main.customData('processor_form__load', { payment: 'paypal', event_label: 'paypal' });
            },
        };

        const payload = {
            ...currentProduct,
            ...meta,
        };

        // @ts-ignore
        dispatch(initPayPalAction(payload));
    }

    function initPaymentGenerator() {
        const metaBank = {
            userId: Number(user_id),
            country,
            currency: getCurrencyName(currency),
        };

        const payload = { ...currentProduct, ...metaBank };

        dispatch(setIsApplePayAvailable(false));
        dispatch(setIsGooglePayAvailable(false));
        // @ts-ignore
        dispatch(initPaymentGeneratorAction(payload));
    }

    const handleChangePaymentMethod = (type: PaymentMethod) => dispatch(changePaymentMethod(type));

    return {
        paymentType,
        isLoading,
        handleChangePaymentMethod,
        payment: {
            initPayPal,
            initPaymentGenerator,
        },
    };
}
